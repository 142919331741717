<script setup>
// #region Imports
import { useRouter } from 'vue-router'
import stripeService from '@/services/stripeService'
import userService from '@/services/userService'
import authService from '@/services/authService'

// #endregion

// #region Router
const router = useRouter()
// #endregion

// #region Methods
const checkRoute = (name) => {
  router.push(name)
}
// #endregion

// #region Created
let user
authService.checkTokens().then((_) => userService
  .getUser())
  .then((response) => {
    user = response.data
    return stripeService.getPlanId()
  }).then((response) => {
    if (response.data) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: response.data,
        user: user
      })
    }
  }).finally(function() {
    stripeService.sendPriceToPixel()
  })

// #endregion
</script>

<template>
  <v-container class="wrapper-container pa-0">
    <v-container class="content fill-height">
      <v-row justify="center" class="ma-1">
        <v-col cols="12" sm="12" align="center">
          <v-container class="main-title my-5">
            <v-img src="@/assets/img/Joana_Horizontal_v1.png"
              style="display: block; margin-left: auto; margin-right: auto">
            </v-img>
          </v-container>
        </v-col>
        <v-col cols="12" align="center">
          <v-icon dark color="success" class="mr-2"> mdi-check-circle </v-icon>
          <span>{{ $t('checkout.validated.title') }}</span>
        </v-col>
        <v-col cols="12" align="center">
          <span>{{ $t('checkout.validated.description') }}</span>
        </v-col>
        <v-col cols="12" align="center">
          <span class="link-lowercase" @click="checkRoute({ name: 'MealPlan' })">
            {{ $t('checkout.validated.goBack') }}
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped lang="scss">
.content {
  max-width: 500px !important;
}

.main-title {
  font-size: 40px;
  line-height: 41px;
  letter-spacing: -0.02em;
}
</style>
